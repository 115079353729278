"use strict";
/*
 *
 * Форматирует число и соответствующее ему существительное
 *
 * Параметры:
 *
 * n — число
 *
 * nouns: [s0, s1, s2, s3?] — формы существительного
 *   например:
 *     1 компьютер <- s0
 *     2 компьютера <- s1
 *     5 компьютеров <- s2
 *     0.3 компьютера <- s3 (s3 обычно совпадает с s1, необязательный)
 *
 * numberFormatCallback — колбек для форматирования числа, можно указать () => '',
 *                        для того, чтобы убрать вывод числа
 *
 */
Object.defineProperty(exports, "__esModule", { value: true });
function RussianNoun(n, nouns, numberFormatCallback = (n) => String(!isNaN(n) ? n : '')) {
    let numberString = numberFormatCallback(n);
    numberString = numberString ? numberString + ' ' : '';
    if (n % 1) {
        return numberString + (nouns[3] || nouns[1]);
    }
    const d1 = n % 10;
    const d2 = Math.floor(n / 10) % 10;
    if (d2 === 1) {
        return numberString + nouns[2];
    }
    else if (~[2, 3, 4].indexOf(d1)) {
        return numberString + nouns[1];
    }
    else if (d1 === 1) {
        return numberString + nouns[0];
    }
    return numberString + nouns[2];
}
exports.default = RussianNoun;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FormatMoney = void 0;
function FormatMoney(n, options) {
    const sign = (options === null || options === void 0 ? void 0 : options.noCurrency) ? '' : ' ₽';
    if (isNaN(n)) {
        return `—${sign}`;
    }
    let money = Math.round(n * 100);
    const roubles = Math.floor(money / 100);
    const cents = money % 100;
    if (cents === 0) {
        if (roubles < 10000) {
            return `${roubles}${sign}`;
        }
        return `${roubles.toLocaleString('ru-RU')}${sign}`;
    }
    const centsString = cents >= 10 ? String(cents) : '0' + cents;
    if (roubles < 10000) {
        return `${roubles},${centsString}${sign}`;
    }
    return `${roubles.toLocaleString('ru-RU')},${centsString}${sign}`;
}
exports.FormatMoney = FormatMoney;

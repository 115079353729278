"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function CreateFormDataFromObject(object) {
    const formData = new FormData();
    if (!object || typeof object !== 'object') {
        return formData;
    }
    Object.entries(object).forEach(([key, value]) => {
        if (object.hasOwnProperty(key)) {
            formData.append(key, value);
        }
    });
    return formData;
}
exports.default = CreateFormDataFromObject;
